import React from "react";
import { graphql } from "gatsby"

const User = ({data}) => {

  return (
    <div className="userHookSv"></div>
  )
}

export default User

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
  }
`